<template>
  <b-card v-if="isLoaded">
    <b-tabs
      nav-wrapper-class="border-b"
      pills>
      <b-tab
        active
        title-link-class="rounded-pill"
        title="Ticket Detail"
        @click="refreshTabDetail()">
        <TicketDetail
          :ticket-props="ticket"
          :customer-detail="customerProfile"
          @update-ticket="ticket = $event"
          @return-ticket="recieveTicketData($event)"
        />
      </b-tab>
      <b-tab
        title-link-class="rounded-pill"
        :title="`Customer Profile ${countProfileAndContact > 0 ? `(${countProfileAndContact})` : ''}`"
      >
        <TicketProfile
          v-if="isRecievedTicket"
          :all-ref="allRef"
          :ticket="ticket"
          :customer-profile="customerProfile"
          :customer-id="ticket.customerProfileId"
          :customer-ani="ticket.contactPhoneNo"
          :customer-items.sync="customerProfileItems"
          @select-customer-and-contact="selectCustomerAndContact($event)"
          @clear-customer-and-contact="clearCustomerAndContact()"
          @search-customer="getCustomerProfileByAni($event)"
          @search-customer-id="getCustomerProfileById($event)"
          @search-name-customer="getCustomerProfileByName($event)"
          @select-customer="selectCustomer($event)"
          @reset-form="resetFormTicketProfile()"
          @update-count-customer-profile="countProfileAndContact = $event"
        />
      </b-tab>
      <b-tab
        v-if="ticket && ticket.customerProfileId"
        title-link-class="rounded-pill"
        title="Ticket list">
        <CustomerHistoryTicketTable
          v-if="isReloadTabs"
          :customer-profile-id="ticket.customerProfileId"
          :current-ticket-id="ticket.id"
        />
        <!-- <b-card-text>
        </b-card-text> -->
      </b-tab>
      <!-- <b-tab
        title-link-class="rounded-pill"
        title="Email">
        <TicketEmail
          :ticket-id="this.$route.params.id"
          class="mt-4"
        />
      </b-tab> -->
      <b-tab
        v-for="iframe in iframeTabs"
        :key="iframe.title"
        title-link-class="rounded-pill"
        :title="iframe.title">
        <iframe
          :title="iframe.title"
          :src="iframe.src"
          :width="iframe.width"
          :height="iframe.height"
          frameborder="0"
          style="height: 100vh;"
        />
      </b-tab>
      <!-- <b-tab
        title-link-class="rounded-pill"
        title="Invoice">
        <b-card-text>
        </b-card-text>
      </b-tab> -->
      <!-- <template>
      </template> -->
    </b-tabs>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex'
import dayjs from 'dayjs'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import CustomerProvider from '@/resources/CustomerProvider'
// import TicketTypeProvider from '@/resources/TicketType'
import CategoriesProvider from '@/resources/CategoriesProvider'
// import PlantProvider from '@/resources/PlantProvider'
import TicketProvider from '@/resources/TicketProvider'
// import ContactChannleProvider from '@/resources/ContactChannelProvider'
// import CallCategoryProvider from '@/resources/CallCategoryProvider'
import UploadService from '@/resources/UploadProvider'
import resizeBase64ImagesInHTML from '@/utils/resize-image-base-64-html'
import TicketDetail from './TicketDetail.vue'
import TicketProfile from './TicketProfile.vue'
import CustomerHistoryTicketTable from './CustomerHistoryTicketTable.vue'
// const ContactChannelService = new ContactChannleProvider()
// const CallCategoryServie = new CallCategoryProvider()

const CustomerSerivce = new CustomerProvider()

const CategoriesService = new CategoriesProvider()
const TicketService = new TicketProvider()
// const TicketTypeService = new TicketTypeProvider()
// const PlantService = new PlantProvider()

export default {
  components: {
    TicketDetail,
    TicketProfile,
    CustomerHistoryTicketTable
  },
  data () {
    return {
      countProfileAndContact: 0,
      isShowComponent: true,
      isRecievedTicket: false,
      isReloadTabs: true,
      isLoaded: false,
      customerProfileItems: [],
      initialCustomerProfile: {
        id: '',
        customerId: '',
        name: '',
        customerTypeId: '',
        customerGroupId: null,
        sapSoldToCode: '',
        sapShipToCode: '',
        refCode1: '',
        refCode2: '',
        refCode3: '',
        companyAddress: '',
        companySubDistrict: '',
        companyDistrict: '',
        companyProvince: '',
        companyZipCode: '',
        companyOfficePhone: '',
        companyMobilePhone: '',
        companyEmail: '',
        companyGroupId: null,
        companyDepartmentId: null,
        companyCenterId: null,
        remark1: '',
        remark2: '',
        remark3: '',
        queueAgent: 1
      },
      customerProfile: {
        id: '',
        customerId: '',
        name: '',
        customerTypeId: '',
        customerGroupId: null,
        sapSoldToCode: '',
        sapShipToCode: '',
        refCode1: '',
        refCode2: '',
        refCode3: '',
        companyAddress: '',
        companySubDistrict: '',
        companyDistrict: '',
        companyProvince: '',
        companyZipCode: '',
        companyOfficePhone: '',
        companyMobilePhone: '',
        companyEmail: '',
        companyGroupId: null,
        companyDepartmentId: null,
        companyCenterId: null,
        remark1: '',
        remark2: '',
        remark3: '',
        queueAgent: 1
      },
      ticket: {
        code: '',
        ticketTypeId: null,
        contactChannelId: null,
        callCategoryId: null,
        priority: '',
        customerName: '',
        contactName: '',
        contactPhoneNo: '',
        categoryLv1: null,
        categoryLv2: null,
        categoryLv3: null,
        categoryLv4: null,
        categoryLv5: null,
        categoryLv6: null,
        plantId: '',
        qty: '',
        time: '',
        subject: '',
        ticketCause: '',
        ticketResolve: '',
        ticketDetail: '',
        progressBy: {},
        dueDate: null,
        resolveDate: null,
        remark1: '',
        remark2: '',
        remark3: '',
        ticketStatus: '',
        createdAt: ''
      },
      allRef: {
        ticketType: [],
        contactChannel: [],
        callCategory: [],
        categories: [],
        plant: []
      },
      progressBy: {},
      resolveDate: '',
      dueDate: '',
      item: {}
    }
  },
  computed: {
    iframeTabs () {
      return [
        {
          title: 'Data View 1',
          src: 'https://csc-report.boonrawd.co.th/LastInvoice?custCode=null',
          width: '100%',
          height: '100%'
        },
        {
          title: 'Data View 2',
          src: 'https://csc-report.boonrawd.co.th/LastInvoiceSml?custCode=null&agentCode=null',
          width: '100%',
          height: '100%'
        },
        {
          title: 'Data View 3',
          src: 'https://csc-report.boonrawd.co.th/CumulativeSales?custCode=null',
          width: '100%',
          height: '100%'
        },
        {
          title: 'Data View 4',
          src: ' https://csc-report.boonrawd.co.th/CumulativeSalesSml?custCode=null&agentCode=null',
          width: '100%',
          height: '100%'
        }
      ]
    }
  },
  async mounted () {
    // await this.getTicketById()
    try {
      await this.setInitialTicketAndCustomer(this.item)
      // await this.getAllOptions()
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoaded = true
    }
  },
  methods: {
    ...mapActions({
      deleteTicket: 'ticket/DELETE_TICKET'
      // overwriteTicket: 'ticket/OVERWRITE_TICKET'
    }),
    // async getTicketById () {
    //   try {
    //     const { data } = await TicketService.findOne(this.ticketId)
    //     console.log(data)
    //     const ticket = { ...data }
    //     this.overwriteTicket({ index: this.tabIndex, payload: ticket })
    //     this.ticket.attachFiles = JSON.parse(this.ticket.attachFiles)
    //   } catch (error) {
    //     console.error(error)
    //   }
    // },
    async getCustomerProfileByAni (ani) {
      try {
        if (ani) {
          const { data } = await CustomerSerivce.findByAni(ani)
          if (data) {
            this.setIntialData(data)
            this.ticket.customerName = data.name
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getCustomerProfileById (id) {
      try {
        if (id) {
          const { data } = await CustomerSerivce.findOne(id)
          if (data) {
            this.setIntialData(data)
            this.ticket.customerName = data.name
            this.ticket.contactName = data.name
            this.ticket.contactPhoneNo = data.ani || data.companyMobilePhone || data.companyOfficePhone
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getCustomerProfileByName (name) {
      try {
        const { data } = await CustomerSerivce.paginate(1, 10, { searchCustomerName: name })
        if (data) {
          this.customerProfileItems = data
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getAllOptions () {
      try {
        this.isShowComponent = false
        // const categories = await CategoriesService.findAll()
        // this.allRef.categories = categories?.data
        // const ticketType = await TicketTypeService.findAllType()
        // this.allRef.ticketType = ticketType?.data
        // const contactChannel = await ContactChannelService.findAllChannel()
        // this.allRef.contactChannel = contactChannel?.data
        // const callCategory = await CallCategoryServie.findAllCallCategory()
        // const plant = await PlantService.findAllPlant()
        // this.allRef.callCategory = callCategory.data
        // this.allRef.plant = plant?.data?.map((item) => ({ ...item, code: `${item?.code} - ${item?.name}` }))
      } catch (error) {
        console.error(error)
      } finally {
        this.isShowComponent = true
      }
    },
    async addTicket (val) {
      try {
        const attachFiles = await this.uploadFile(val.files)

        if (attachFiles && attachFiles.length > 0) {
          val.attachFiles.push(...attachFiles)
        }
        const payload = {
          ...val,
          progressBy: this.progressBy,
          attachFiles: JSON.stringify(val.attachFiles),
          customerProfileId: this.customerProfile?.id || null
        }

        delete payload.code
        delete payload.id
        delete payload.createdAt
        delete payload.createdBy
        delete payload.updatedAt
        delete payload.updatedBy
        delete payload.status
        delete payload.ani
        delete payload.files
        payload.ticketDetail = resizeBase64ImagesInHTML(payload.ticketDetail)
        payload.ticketResolve = resizeBase64ImagesInHTML(payload.ticketResolve)
        payload.contactPhoneNo = this.$replacePhoneNumber(payload.contactPhoneNo, '+66')
        await TicketService.updateGroup(val.id, payload)
        // this.deleteTicket(this.tabIndex)
        // this.overwriteTicket({ index: this.tabIndex, payload: data })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ticket Add Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Ticket has been added'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Ticket Add Fail ${error}`,
            icon: 'EditIcon',
            variant: 'danger',
            text: '👋 Ticket has been failed'
          }
        })
      }
    },
    async getResolveAndDue (val) {
      try {
        const { data } = await CategoriesService.findOne(val)
        if (data) {
          this.ticket.priority = data.priority
          const calculateDate = dayjs()
            .add(data.resolvedDay, 'd')
            .add(data.resolvedHour, 'h')
            .add(data.resolvedMin, 'm')
            .format('DD MMMM BBBB HH:mm:ss')
          this.resolveDate = calculateDate
          this.dueDate = calculateDate
        }
      } catch (error) {
        console.error(error)
        this.resolveDate = ''
        this.dueDate = ''
      }
    },
    async uploadFile (files) {
      try {
        if (files && files.length > 0 && typeof files === 'object') {
          const arr = []
          for (const item of files) {
            const formData = new FormData()
            formData.append('file', item.file)
            // eslint-disable-next-line no-await-in-loop
            const { data: { data } } = await UploadService.upload(formData)
            arr.push({ name: item.name, url: data.publicUrl })
          }
          return arr
        }
        return []
      } catch (error) {
        console.error(error)
        return []
      }
    },
    async getCustomerByOne (customerId) {
      try {
        if (customerId) {
          const { data } = await CustomerSerivce.findOne(customerId)
          if (data) {
            this.customerProfile = data
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    async recieveTicketData (ticket) {
      try {
        this.isRecievedTicket = false
        this.ticket = ticket
        if (this.ticket.attachFiles) {
          this.ticket.attachFiles = JSON.parse(this.ticket.attachFiles)
        }
        if (ticket?.customerProfileId) {
          await this.getCustomerByOne(ticket?.customerProfileId)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setTimeout(() => {
          this.isRecievedTicket = true
        }, 1000)
      }
    },
    setIntialData (val) {
      this.customerProfile = { ...val }
    },
    setInitialTicketAndCustomer (data) {
      if (data) {
        this.ticket = { ...data }
        if (data?.customerProfileId) {
          this.getCustomerByOne(data?.customerProfileId)
        }
      }
    },
    selectCustomer (val) {
      if (val && val.id) {
        this.customerProfile = val
        this.customerProfile.id = val.id
      } else {
        this.resetFormTicketProfile()
      }
    },
    selectCustomerAndContact (val) {
      this.isReloadTabs = false
      if (val && val.id) {
        this.customerProfile = val || ''
        this.customerProfile.id = val.id || 0
        this.ticket.customerName = val.name || ''
        this.ticket.contactName = val?.contactName || val?.name || ''
        this.ticket.customerProfileId = val.id || ''
        this.ticket.contactPhoneNo = val?.ani || val?.companyMobilePhone || val?.companyOfficePhone || val?.contactPhoneNo || val?.contactPhoneNo2 || ''
      }
      setTimeout(() => {
        this.isReloadTabs = true
      }, 1000)
    },
    clearCustomerAndContact () {
      this.isReloadTabs = false
      this.customerProfile = null
      this.ticket.customerName = ''
      this.ticket.contactName = ''
      this.ticket.customerProfileId = null
      this.ticket.contactPhoneNo = ''
      setTimeout(() => {
        this.isReloadTabs = true
      }, 1000)
    },
    selectUser (val) {
      this.progressBy = val
    },
    resetFormTicketProfile () {
      this.customerProfile = { ...this.initialCustomerProfiles }
    },
    returnTicket (e) {
      this.item = e
    },
    refreshTabDetail () {
      this.isShowComponent = !this.isShowComponent
      this.$nextTick(() => {
        this.isShowComponent = !this.isShowComponent
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
